import React from 'react';
import one from '../img/s1.png';
import two from '../img/s2.png';
import three from '../img/s3.png';

const Services = () => {
  return (
    <section className="services" id="services">
      <h2>Our Popular<font color="tomato"> Services</font></h2>
      <p className="section-desc">
        With over 50+ services to choose from, check out our most popular
        services.
      </p>

      <div className="row">
        <div className="column">
          <img src={one} alt="JRP Soft Tech" />
          <h3>Website Development</h3>
          <p>
            At JRP Soft Tech, we're committed to delivering high-quality
            website development services that meet the needs of our clients.
            We're proud of our track record of delivering projects on time and
            within budget, and we're confident that we can help you achieve
            your online goals. Contact us today to learn more about our
            website development services.
          </p>
          <div className="services-btn">
            <a href="https://wa.me/917977874412">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        </div>
        
        <div className="column">
          <img src={two} alt="JRP Soft Tech" />
          <h3>SEO</h3>
          <p>
            Our SEO services are designed to help businesses succeed in
            today's competitive online landscape. Whether you're a small local
            business or a large multinational corporation, we have the
            expertise and experience to help you achieve your SEO goals.
            Contact us today to learn more about our SEO services and how we
            can help your business grow.
          </p>
          <div className="services-btn">
            <a href="https://wa.me/917977874412">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        
        </div>

        <div className="column">
          <img src={three} alt="JRP Soft Tech" />
          <h3>Logo Design</h3>
          <p>
            At JRP Soft Tech, we believe in providing high-quality logo design
            services that are both affordable and effective. We understand that
            a great logo is more than just a pretty picture - it's a key part
            of your brand identity that helps you stand out from the
            competition. That's why we work hard to create logos that are not
            only visually appealing but also communicate the essence of your
            business.
          </p>
          <div className="services-btn">
            <a href="https://wa.me/917977874412">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        </div>
        </div>
      
    </section>
  );
};

export default Services;
