import React from 'react';
import one from '../img/Download_on_the_App_Store_Badge.svg.webp';
import two from '../img/Google_Play_Store_badge_EN.svg.png';
import './installapp.css';
import three from '../img/mockup.png';

const JRPSoftTechApp = () => {
  const appDownloadLinks = {
    playStore: 'url_to_play_store_download',
    appStore: 'url_to_app_store_download',
  };

  return (
    <div className="jrp-soft-tech-app-section">
      <h2>Download the JRP Soft Tech App</h2>
      <h4>Download app from </h4>
      
      <div className="download-links">
        <a href={appDownloadLinks.playStore} target="_blank" rel="noopener noreferrer">
          <img src={one} alt="Download on Play Store" />
        </a>
        <a href={appDownloadLinks.appStore} target="_blank" rel="noopener noreferrer">
          <img src={two} alt="Download on App Store" />
        </a>
      </div>
      <div className="app-mockup">
        <img src={three} alt="App Mockup" />
      </div>
      
    </div>
  );
};

export default JRPSoftTechApp;

