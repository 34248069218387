import React from 'react';

const Map = () => {
  return (
    <section className="map" id="map">
      <div className="map-1">
        <iframe
          title="JRP SOFT TECH"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14311.488612561508!2d72.9922827!3d26.2658124!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2bbef33fc487329b%3A0x7d9f124119d5ae85!2sJRP%20SOFT%20TECH!5e0!3m2!1sen!2sin!4v1677144212387!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0, borderRadius: '10px' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default Map;
