import React from 'react';
import { FaStar, FaThumbsUp, FaUsers, FaBullhorn, FaGlobe } from 'react-icons/fa';

const Features = () => {
  return (
    <section className="features" id="features">
      <h2>
        Why<font color="tomato"> Choose Us</font>
      </h2>
      <p className="section-desc">
        <strong>We at JRP Soft Tech provide you with complete software
        development solutions.</strong>
      </p>
      <div className="row">
        <div className="column">
          <i className="fas fa-star"><FaStar className="icon" /></i>
          <h3>5+ Years of Experience</h3>
        </div>
        <div className="column">
          <i className="fas fa-thumbs-up"><FaThumbsUp className="icon" /></i>
          <h3>100+ Successful projects</h3>
        </div>
        <div className="column">
          <i className="fas fa-users"><FaUsers className="icon" /></i>
          <h3>100+ Happy Clients</h3>
        </div>
        <div className="column">
          <i className="fas fa-bullhorn"><FaBullhorn className="icon" /></i>
          <h3>120+ cities served</h3>
        </div>
        <div className="column">
          <i className="fas fa-bullhorn"><FaGlobe className="icon" /></i>
          <h3>Across 10+ countries </h3>
        </div>
      </div>
    </section>
  );
};

export default Features;
