import React from 'react';

import one from '../img/b5.png';
import two from '../img/app.png';
import three from '../img/b1.png';
import four from '../img/web.png';
import five from '../img/jrpds.png';
import six from '../img/jrphosting.png';


import './brands.css';

const Brands = () => {
  // Sample data - replace this with your actual data
  const brands = [
    { id: 1, name: 'JRP WebTech', imageUrl:four },
    { id: 2, name: 'App Solutions', imageUrl: two },
    { id: 3, name: 'JRP SEO Boost', imageUrl: one },
    { id: 4, name: 'JRP Hosting ', imageUrl: six },
    { id: 5, name: 'JRP Design Studio', imageUrl: five },
    { id: 6, name: 'JRP Digital Reach', imageUrl: three }, 
    // Add more brands as needed
  ];

  return (
    <div className="brands-section">
      <h2>Our Brands</h2>
      <br/>
      <div className="brand-list">
        {brands.map(brand => (
          <div key={brand.id} className="brand-item">
            <img src={brand.imageUrl} alt={brand.name} />
            <p>{brand.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
