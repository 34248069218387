import React from 'react';
import jrpsofttech from '../img/laptop.png';


const Hero = () => {
  return (
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-content">
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
          Your Digital Partner for Growth
        </h1>
        <p style={{ color: 'white', fontSize: '2rem' }}>
          JRP Soft Tech is a software company based in Jodhpur, Rajasthan,
          India. The company provides a range of IT services such as web
          development, custom software development, digital marketing, and IT
          consulting services to businesses of all sizes and industries. JRP
          Soft Tech use the latest technologies and tools to deliver
          innovative solutions to clients.
        </p>
        <div className="btn-container">
          <a href="https://wa.me/917977874412">
            <button className="primary-btn btn">Get a Quote</button>
          </a>
          <a href="#features">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
      </div>
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
    </section>
  );
};

export default Hero;



