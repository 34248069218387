import React, { useState } from 'react';
import  care from '../img/contact.png';
const Contact = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    subject: '',
  });

  const validateForm = () => {
    // Add your form validation logic here
    // Return true if the form is valid, false otherwise
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Perform actions like submitting the form data
      console.log('Form submitted:', formData);
    } else {
      console.log('Form validation failed.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section className="Contact-Us" id="Contact-Us">
      <h2>Contact<span style={{ color: 'tomato' }}> Us</span></h2>
      <p className="section-desc">
        Want a website? Contact us.
      </p>
      <div className="row">
        <div className="column">
          <img src={care} alt="Customer Care img" />
        </div>
        <div className="column">
          <div className="info">
            <form name="myForm" onSubmit={handleSubmit}>
              <label htmlFor="fname">First Name : </label>
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder="Your name.."
                value={formData.fname}
                onChange={handleChange}
              />
              <br/><br/>
              <label htmlFor="lname">Last Name : </label>
              <input
                type="text"
                id="lname"
                name="lname"
                placeholder="Your last name.."
                value={formData.lname}
                onChange={handleChange}
              />
              <br/><br/>
              <label htmlFor="email">Email : </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your email.."
                value={formData.email}
                onChange={handleChange}
              />
              <br/><br/>
              <label htmlFor="subject">Subject :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
              <textarea
                id="subject"
                name="subject"
                placeholder="Write something.."
                style={{ height: '80px' }}
                value={formData.subject}
                onChange={handleChange}
              ></textarea>
              <br/><br/>
              <input type="submit" value="Submit" />
            </form>
          </div>
          <div className="btn">
            <button className="primary-btn btn">
              <a href="https://forms.gle/bUMkhCvPGMQbky856" style={{ textDecoration: 'none', color: 'white' }}>BOOK Appointment</a>
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="primary-btn btn">
              <a href="https://wa.me/917977874412" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>Contact-Us</a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
