import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Services from './components/Services';
import Brands from './components/brands';
import JRPSoftTechApp from './components/install_app';

import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
import Faq from './components/faq';

import Footer from './components/Footer';
import Map from './components/map';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Features />
      <Services />
      <Brands/>
      <Testimonial />
      <JRPSoftTechApp/>
      <Faq/>
      <Contact />
      <Map/>
      <ScrollToTopButton/>
      <Footer />
    </div>
  );
}

export default App;



