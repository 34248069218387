import React from 'react';
import './faq.css'
const Faq = () => {
  return (
    <section class="faq" id="faq">
      <h2>Frequently Asked Questions</h2>
      <p class="section-desc">
        Have questions about our services ? We've got answers.
      </p>
      <div class="faq-container">
        
    <div class="faq">
      {/*
    <h1>Frequently Asked Questions</h1>
      */}
    <div class="faq-item">
        <input type="checkbox" id="faq1"/>
        <label for="faq1" class="faq-item-title"><span class="icon"></span>1. What services does JRP Soft Tech provide?</label>
        <div class="faq-item-desc">JRP Soft Tech offers a range of software solutions tailored to meet the diverse needs of businesses. Our services include software development, application customization, system integration, and IT consulting.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq2"/>
        <label for="faq2" class="faq-item-title"><span class="icon"></span>2. How does the software development process work at JRP Soft Tech?</label>
        <div class="faq-item-desc">Our software development process involves a comprehensive approach, from understanding your requirements to design, development, testing, and deployment. We prioritize collaboration and communication to ensure the end product aligns with your vision.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq3"/>
        <label for="faq3" class="faq-item-title"><span class="icon"></span>3. What technologies does JRP Soft Tech specialize in?</label>
        <div class="faq-item-desc">Our team is proficient in a wide array of technologies, including but not limited to Java , Dart , Python, .NET, JavaScript , and database management systems. We stay updated with the latest industry trends to provide cutting-edge solutions.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq4"/>
        <label for="faq4" class="faq-item-title"><span class="icon"></span>4. Can JRP Soft Tech customize existing software or applications?</label>
        <div class="faq-item-desc">Absolutely. Whether it's modifying an off-the-shelf software solution or customizing an existing application, our team has the expertise to tailor software to your specific requirements.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq5"/>
        <label for="faq5" class="faq-item-title"><span class="icon"></span>5. How long does it take to develop custom software with JRP Soft Tech?</label>  
        <div class="faq-item-desc">
        The timeline for custom software development varies based on the complexity and scope of the project. We work closely with our clients to establish realistic timelines and keep them informed throughout the development process.</div>
    </div>


    <div class="faq-item">
        <input type="checkbox" id="faq6"/>
        <label for="faq6" class="faq-item-title"><span class="icon"></span>6. What industries does JRP Soft Tech serve?</label>  
        <div class="faq-item-desc">
        We have experience serving a diverse range of industries, including finance, healthcare, e-commerce, education, and more. Our versatile team adapts our solutions to meet the unique challenges of each industry.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq7"/>
        <label for="faq7" class="faq-item-title"><span class="icon"></span>7. Does JRP Soft Tech provide ongoing support after software deployment?</label>  
        <div class="faq-item-desc">
        Yes, we offer post-deployment support and maintenance services. Our team is committed to ensuring the smooth operation of your software, addressing any issues that may arise, and implementing updates as needed. </div>
    </div>



    <div class="faq-item">
        <input type="checkbox" id="faq8"/>
        <label for="faq8" class="faq-item-title"><span class="icon"></span>8. How can I get started with JRP Soft Tech for my software needs?</label>  
        <div class="faq-item-desc">
        Getting started is simple. Reach out to us through our website, and our team will promptly connect with you to discuss your project, goals, and how we can best assist you. </div>
    </div>


    <div class="faq-item">
        <input type="checkbox" id="faq9"/>
        <label for="faq9" class="faq-item-title"><span class="icon"></span>9. What sets JRP Soft Tech apart in the software development industry?</label>  
        <div class="faq-item-desc">
        Our commitment to quality, innovation, and client satisfaction sets us apart. We combine technical expertise with a client-centric approach to deliver software solutions that exceed expectations.</div>
    </div>
    <div class="faq-item">
        <input type="checkbox" id="faq10"/>
        <label for="faq10" class="faq-item-title"><span class="icon"></span>Have a Question Not Answered Here?</label>  
        <div class="faq-item-desc">
        Feel free to contact us directly, and our team will be happy to provide any additional information you need.</div>
    </div>

</div>
      </div>
    </section>
  );
};

export default Faq;



