import React from 'react';
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-4 col-xs-12">
            <div className="single_footer">
            
              <h4 >Our Brands</h4>
              <ul>
              <li>
                  <a href="https://jrpwebtech.jrpsofttech.com/">JRP WEB TECH</a>
                </li>
                <li>
                  <a href="https://appsolutions.jrpsofttech.com/">App Solutions</a>
                </li>
                <li>
                  <a href="https://jrpseoboost.jrpsofttech.com/">JRP SEO Boost</a>
                </li>
                <li>
                  <a href="https://jrphosting.jrpsofttech.com/">JRP Hosting</a>
                </li>
                <li>
                  <a href="https://jrpdesignstudio.jrpsofttech.com/">JRP Design Studio</a>
                </li>
                <li>
                  <a href="https://jrpdigitalreach.jrpsofttech.com/">JRP Digital-Reach</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer single_footer_address">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <a href="https://jrpsofttech.com/">JRP Soft Tech</a>
                </li>
                <li>
                  <a href="https://jrpsofttech.com/">Affiliate Program</a>
                </li>
                <li>
                  <a href="https://jrpsofttech.com/">Careers</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer single_footer_address">
              <h4>Contact Us</h4>
              <div className="signup_form">
                <p>
                  <a href="tel:+91 7977874412" style={{ color: 'white' }}>
                    +91-7977874412
                  </a>
                </p>
              </div>
            </div>
            <div className="social_profile">
              <div className="rounded-social-buttons">
                <ul>
                  <li>
                    <a
                      className="social-button linkedin"
                      href="https://www.linkedin.com/in/jay-rajpurohit-page/"
                      target="_end"
                    >
                      <i className="fab fa-linkedin">
                        <FaLinkedinIn/>
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="social-button whatsapp"
                      href="https://wa.me/917977874412"
                      target="_new"
                    >
                      <i className="fab fa-whatsapp">
                        <FaWhatsapp/>
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="social-button instagram"
                      href="https://www.instagram.com/jrpsofttech/?next=%2F"
                      target="_new"
                    >
                      <i className="fab fa-instagram">
                        <FaInstagram/>
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          
        </div>
        
      </div>
      <p className="copyright">
            Copyright &#169; 2023{' '}
            <a href="#home">JRP Soft Tech</a>. All Rights Reserved . A <a href='https://jrpgroup.jrpsofttech.com/'> JRP Group</a> Company.
            Made with &#10084; in INDIA .
          </p>
    </div>
  );
};

export default Footer;
